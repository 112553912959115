export default class ReceiptFinderResponseDto {
    url: string | null = null;
    dateTime: string = "";

    constructor(
        ReceiptFinderResponseDtoPart: Partial<ReceiptFinderResponseDto> = {}
    ) {
        Object.assign(this, ReceiptFinderResponseDtoPart);
    }

    toJSON() {
        return {
            url: this.url,
            dateTime: this.dateTime,
        };
    }

    static testValuesFactoryAllNull() {
        return new ReceiptFinderResponseDto({
            url: null,
            dateTime: "zpUCjzDAvfBwgkvbIStssYRKehXlrAdp",
        });
    }

    static testValuesFactorySomeNull() {
        return new ReceiptFinderResponseDto({
            url: null,
            dateTime: "xvSwyOnHBDoudrJDFnSqQponmggYeiRH",
        });
    }

    static testValuesFactoryNoneNull() {
        return new ReceiptFinderResponseDto({
            url: "OBZBYmZzFMlzjLSbtllfXUFLxqoSrBnn",
            dateTime: "WZIgsyVZVyjVjWVsWDjzLVpNgZmnZaHr",
        });
    }
}
