<template>
  <v-container fluid>
    <v-col class="pt-5" style="min-height: 80vh" cols="12" align="center">
      <img :src="customerIcon" style="height: 50px; object-fit: contain" />
      <p v-if="vendorLoaded" class="text-h6 text-left mt-10">{{ $t("title") }}</p>
      <div v-if="vendorLoaded" class="pt-8 mx-auto">
        <v-combobox
          v-if="!storeSelected"
          v-model="selectedStore"
          :items="stores"
          :label="t('storeSelectLabel')"
          variant="outlined"
          item-title="name"
          clearable
          item-value="uniqueKey"
          density="comfortable"
          :custom-filter="filterStores"
          class="store-selector"
        >
          <template #item="{ props, item }">
            <v-list-item :key="item.raw.uniqueKey" width="300px" v-bind="props">
              <v-list-item-subtitle>{{
                `${item.raw.address.street} ${item.raw.address.number}, ${item.raw.address.city}`
              }}</v-list-item-subtitle>
            </v-list-item>
          </template>
          <template #append>
            <v-tooltip location="left" open-on-hover open-on-focus>
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="mdi-help-circle-outline" />
              </template>
              <span style="white-space: pre-line" v-html="$t('storeSelectTooltip')" />
            </v-tooltip> </template
        ></v-combobox>
        <div
          v-else
          :class="`v-input v-input--horizontal v-input--center-affix ${
            receiptSelectionEnabled ? 'v-input--disabled' : ''
          } v-input--density-comfortable v-theme--light v-locale--is-ltr v-text-field`"
        >
          <div class="v-input__control">
            <div
              :class="`v-field v-field--center-affix ${
                receiptSelectionEnabled ? 'v-field--disabled' : ''
              } v-field--variant-outlined v-theme--light v-locale--is-ltr`"
            >
              <div class="v-field__overlay" bis_skin_checked="1"></div>
              <div
                class="v-field__field"
                style="
                  border: thin solid rgba(0, 0, 0, 0.38);
                  border-radius: 4px;
                  position: relative;
                "
              >
                <v-col style="padding: 2px 12px">
                  <div class="text-body-1 font-weight-medium text-left">
                    {{ selectedStore?.name }}
                  </div>
                  <div class="text-caption text-medium-emphasis text-left">
                    {{
                      `${selectedStore?.address.street} ${selectedStore?.address.number}, ${selectedStore?.address.city}`
                    }}
                  </div>
                </v-col>
              </div>
            </div>
          </div>
          <div class="v-input__append" bis_skin_checked="1">
            <div class="d-flex align-center">
              <v-icon
                class="cursor-pointer"
                color="grey-darken-1"
                icon="mdi-close"
                :disabled="receiptSelectionEnabled"
                @click="selectedStore = null"
              />
            </div>
          </div>
          <div
            id="input-4-messages"
            class="v-input__details"
            role="alert"
            aria-live="polite"
            bis_skin_checked="1"
          >
            <div class="v-messages" bis_skin_checked="1"></div>
            <!---->
          </div>
        </div>

        <v-text-field
          v-model="receipt.fullAmountInclVat"
          :label="approximateAmountEnabled ? t('approximateAmount') : t('amountLabel')"
          :disabled="receiptSelectionEnabled"
          placeholder="0,00"
          variant="outlined"
          density="comfortable"
          @input="onAmountInput"
          ><template #append>
            <v-tooltip location="left" open-on-hover open-on-focus>
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="mdi-help-circle-outline" />
              </template>
              <span
                style="white-space: pre-line"
                v-html="
                  approximateAmountEnabled
                    ? t('approximateAmountTooltip')
                    : t('amountTooltip')
                "
              />
            </v-tooltip> </template
        ></v-text-field>

        <v-text-field
          v-model="date"
          :label="t('datepickerLabel')"
          type="date"
          min="2015-01-01"
          :max="maxDate"
          :disabled="receiptSelectionEnabled"
          variant="outlined"
          density="comfortable"
          ><template #append>
            <v-tooltip location="left" open-on-hover open-on-focus>
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="mdi-help-circle-outline" />
              </template>
              <span style="white-space: pre-line" v-html="t('datepickerTooltip')" />
            </v-tooltip> </template
        ></v-text-field>

        <v-text-field
          v-if="approximateTimeEnabled"
          v-model="time"
          :label="t('approximateTime')"
          type="time"
          variant="outlined"
          density="comfortable"
          ><template #append>
            <v-tooltip location="left" open-on-hover open-on-focus>
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="mdi-help-circle-outline" />
              </template>
              <span style="white-space: pre-line" v-html="t('approximateTimeTooltip')" />
            </v-tooltip> </template
        ></v-text-field>

        <v-text-field
          v-model="receipt.lastFourDigits"
          :label="t('labelPaymentType')"
          :maxlength="4"
          placeholder="XXXX"
          :disabled="receiptSelectionEnabled"
          variant="outlined"
          density="comfortable"
          @input="on4DigitsInput"
          ><template #append>
            <v-tooltip location="left" open-on-hover open-on-focus>
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="mdi-help-circle-outline" />
              </template>
              <span style="white-space: pre-line" v-html="t('cardNumbersTooltip')" />
            </v-tooltip> </template
        ></v-text-field>
        <vue-friendly-captcha
          v-if="renderCaptcha && !receiptSelectionEnabled"
          sitekey="FCMJRJC372FUEPFR"
          style="
            border-color: black;
            border-radius: 10px;
            padding: 8px;
            margin: 10px 0;
            width: 100%;
          "
          :language="currentLocale"
          @done="onCaptchaDone"
        />
        <div v-if="receiptSelectionEnabled" class="z-index: 10">
          <div class="my-5">
            <span v-html="$t('infoMultireceipt')" />
          </div>
          <v-combobox
            :key="key"
            v-model="selectedReceipt"
            :items="receiptSelection"
            :label="t('timeLabel')"
            variant="outlined"
            item-title="dateTime"
            density="comfortable"
            @update:modelValue="onReceiptSelect"
          >
            <template #append>
              <v-tooltip location="left" open-on-hover open-on-focus>
                <template #activator="{ props }">
                  <v-icon v-bind="props" icon="mdi-help-circle-outline" />
                </template>
                <span style="white-space: pre-line" v-html="$t('datepickerTooltip')" />
              </v-tooltip> </template
          ></v-combobox>
        </div>
        <v-btn
          class="text-none w-100 mb-15 my-5"
          rounded
          variant="flat"
          :disabled="!isValid"
          color="black"
          :loading="submitBtnLoading"
          @click="onSubmit"
        >
          {{ receiptSelectionEnabled ? t("requestMultiple") : t("requestBeleg") }}
        </v-btn>
        <div style="height: 50px" />
      </div>
      <div
        style="
          height: 50vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
        v-else
      >
        <v-progress-circular indeterminate size="large"></v-progress-circular>
        <p class="text-h6 mt-5">{{ $t("vendorLoading") }}</p>
      </div>
    </v-col>

    <AnybillBranding class="branding" />
    <v-snackbar v-model="snackbar" color="red" timeout="4000">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import AnybillBranding from "./general/anybill-branding.vue";
import MinimalStoreDetailsDto from "~/models/customer/MinimalStoreDetailsDto";
import type ReceiptFinderFeature from "~/models/customer/ReceiptFinderFeatureDto";
import ReceiptFindDto from "~/models/receipt/ReceiptFindDto";
import { AnybillProvider } from "~/provider/anybillProvider";
import VueFriendlyCaptcha from "@somushq/vue3-friendly-captcha";
import ReceiptCustomFilter from "~/models/receipt/ReceiptCustomFilter";
import ReceiptFinderResponseDto from "~/models/receipt/ReceiptFinderResponse";
import type UniqueStoreDto from "~/models/customer/UniqueStore";

const { t, locale } = useI18n();
const anybillProvider = AnybillProvider.Instance;

const { id } = useRoute().params;
const submitBtnLoading = ref(false);
const vendorLogo = ref<string | null>(null);
const storeSelected = computed(
  () => !!selectedStore.value && stores.value?.includes(selectedStore.value)
);
const receipt = ref(
  new ReceiptFindDto({
    date: "",
    identifier: "",
    fullAmountInclVat: "",
    lastFourDigits: "",
    storeId: "",
    customFilter: [],
  })
);

const key = ref(0);

const onReceiptSelect = () => {
  key.value++; // Triggers re-render
};

const receiptSelection = ref<ReceiptFinderResponseDto[]>([]);

const selectedReceipt = ref<ReceiptFinderResponseDto | null>(null);

const currentLocale = computed(() => locale.value);

const vendorLoaded = ref(false);

const receiptSelectionEnabled = ref(false);

const snackbarMessage = ref<string | null>(null);

const snackbar = ref(false);

const captchaSolved = ref(false);

const renderCaptcha = ref(true);

const date = ref(new Date().toISOString().slice(0, 10));
const time = ref("");

const stores = ref<UniqueStoreDto[]>([]);

const selectedStore = ref<UniqueStoreDto | null>(null);
const digitRegex = /[+\-/\\(){}\[\]<>!§$%@^°`´&=?*#€¿&_\".,:;a-zA-Z]/g;
const amountRegex = /[+\-/\\(){}\[\]<>!§$%@^°`´&=?*#€¿&_\":;a-zA-Z]/g;

const isValid = computed(
  () =>
    receipt.value.fullAmountInclVat &&
    receipt.value.lastFourDigits?.length === 4 &&
    date.value &&
    selectedStore.value?.storeId &&
    captchaSolved.value
);

watch(currentLocale, (newVal) => {
  // change captcha locale
  renderCaptcha.value = false;
  setTimeout(async () => {
    renderCaptcha.value = true;
  }, 1);
});

const customerIcon = computed(() => vendorLogo.value || "/anybill_logo.svg");
const maxDate = computed(() => new Date().toISOString().split("T")[0]);

const receiptFinderFeature = ref<ReceiptFinderFeature | null>(null);

const approximateAmountEnabled = computed(
  () => receiptFinderFeature.value?.isApproximateAmountEnabled ?? false
);
const approximateTimeEnabled = computed(
  () => receiptFinderFeature.value?.isApproximateTimeEnabled ?? false
);

function processStores(stores: MinimalStoreDetailsDto[]): UniqueStoreDto[] {
  return stores.map((store, index) => {
    // Create a new object that fully implements UniqueStoreDto
    const uniqueStore: UniqueStoreDto = {
      ...store,
      uniqueKey: `${store.storeId}-${store.address.street}-${store.address.number}-${index}`,
      toJSON: () => ({
        name: store.name,
        storeId: store.storeId,
        address: store.address,
      }),
    };
    return uniqueStore;
  });
}

// In your component setup

onMounted(async () => await getVendorInfo());

const filterStores = (val: string, query: string, item: any): boolean => {
  if (!item || !item.raw) return false;

  const searchTerms = query.toLowerCase().split(/\s+/).filter(Boolean);

  const storeText = `${item.raw.name ?? ""} ${item.raw.address?.street ?? ""} ${
    item.raw.address?.number ?? ""
  } ${item.raw.address?.city ?? ""}`.toLowerCase();

  return searchTerms.every((term) => storeText.includes(term));
};

async function onSubmit() {
  if (receiptSelectionEnabled.value) {
    window.open(selectedReceipt.value?.url ?? "", "_self");
  } else {
    const receiptFindDto = new ReceiptFindDto({
      date: receipt.value.date,
      identifier: receipt.value.identifier,
      fullAmountInclVat: receipt.value.fullAmountInclVat,
      lastFourDigits: receipt.value.lastFourDigits,
      storeId: selectedStore.value?.storeId ?? "",
      customFilter: [],
    });
    if (time.value?.length > 0) {
      receipt.value.date = new Date(`${date.value}T${time.value}Z`)
        .toISOString()
        .replace(/\.\d+Z$/, ".0:00");
      receiptFindDto.customFilter.push(ReceiptCustomFilter.ApproximateTime);
    } else {
      receiptFindDto.date = new Date(date.value).toISOString().slice(0, 10);
    }
    if (approximateAmountEnabled.value)
      receiptFindDto.customFilter.push(ReceiptCustomFilter.ApproximateAmount);
    submitBtnLoading.value = true;
    receiptFindDto.fullAmountInclVat = receipt.value.fullAmountInclVat.replace(",", ".");
    const response = await anybillProvider.findReceipt(receiptFindDto);
    submitBtnLoading.value = false;
    if (response.success) {
      switch (response.value.length) {
        case 0:
          snackbarMessage.value = t("messageNobill");
          snackbar.value = true;
          break;
        case 1:
          window.open(response.value[0].url ?? "", "_self");
          break;
        default:
          receiptSelection.value = response.value.map(
            (receiptFind) =>
              new ReceiptFinderResponseDto({
                url: receiptFind.url,
                dateTime: new Date(receiptFind.dateTime).toLocaleTimeString(locale.value),
              })
          );
          selectedReceipt.value = receiptSelection.value[0];
          receiptSelectionEnabled.value = true;
          break;
      }
    }
  }
}

function onCaptchaDone() {
  captchaSolved.value = true;
}

function onAmountInput(evt: any) {
  receipt.value.fullAmountInclVat = evt.target.value.replace(amountRegex, "");
}

function on4DigitsInput(evt: any) {
  receipt.value.lastFourDigits = evt.target.value.replace(digitRegex, "");
}

async function getVendorInfo() {
  const getVendorsResponse = await anybillProvider.getVendorInformation(id as string);

  vendorLoaded.value = true;
  if (getVendorsResponse.success) {
    receipt.value.identifier = id as string;
    vendorLogo.value = getVendorsResponse.value.vendorLogo;
    receiptFinderFeature.value = getVendorsResponse.value.features?.find(
      (f) => f?.type === "anybill-receipt-finder"
    ) as ReceiptFinderFeature | null;
    stores.value = processStores(
      getVendorsResponse.value.stores?.filter(
        (store, index, self) =>
          index === self.findIndex((s) => s.storeId === store.storeId)
      ) ?? []
    );

    // Sort stores
    stores.value.sort((a, b) =>
      (a.address.city ?? "").localeCompare(b.address.city ?? "")
    );

    // Set first store as selected
    //selectedStore.value = stores.value[0] ?? null;
  } else {
    snackbarMessage.value = t("vendorFetchError");
    snackbar.value = true;
  }
}
</script>

<style scoped lang="scss">
.branding {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
