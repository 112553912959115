import ReceiptCustomFilter from "./ReceiptCustomFilter";

/**
 * @description Groups all information necessary to find a specific Bill in the Anybill System
 */
export default class ReceiptFindDto {
    /**
     * @description The external identifier of the vendor which issued the bill
     */
    identifier: string | null = null;
    /**
     * @description The store Id where the bill was issued
     */
    storeId: string = "dwmaldwal";
    /**
     * @description The last 4 digits of either the card PAN or IBAN to match against.
     */
    lastFourDigits: string | null = null;
    /**
     * @description The full amount incl. Vat for the receipt
     */
    fullAmountInclVat: string = "";
    date: string = "";

    customFilter: ReceiptCustomFilter[] = [];

    constructor(ReceiptFindDtoPart: Partial<ReceiptFindDto> = {}) {
        Object.assign(this, ReceiptFindDtoPart);
    }

    toJSON() {
        return {
            identifier: this.identifier,
            storeId: this.storeId,
            lastFourDigits: this.lastFourDigits,
            fullAmountInclVat: this.fullAmountInclVat,
            date: this.date,
            customFilter: this.customFilter
        };
    }

    static testValuesFactoryAllNull() {
        return new ReceiptFindDto({
            identifier: null,
            storeId: "ddnwadnadwandjbdkja",
            lastFourDigits: null,
            fullAmountInclVat: "26839",
            date: "",
            customFilter: []
        });
    }

    static testValuesFactorySomeNull() {
        return new ReceiptFindDto({
            identifier: "nOTdUHBPAtXinXgpQyvFewHusJOQYrFK",
            storeId: "ddnwadnadwandjbdkja",
            lastFourDigits: "EJfllIgCQiMysCpMVKATCcfmWkhRYhlK",
            fullAmountInclVat: "83391",
            date: "",
            customFilter: []
        });
    }

    static testValuesFactoryNoneNull() {
        return new ReceiptFindDto({
            identifier: "nULRtckkhRKLyYpxIuzyRQbmZzXlqFNZ",
            storeId: "ddnwadnadwandjbdkja",
            lastFourDigits: "SadzNFoGZihYFYmMDpvYCTwEHZhczups",
            fullAmountInclVat: "14495",
            date: "",
            customFilter: []
        });
    }
}
