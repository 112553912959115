<template>
  <main v-if="mounted">
    <ReceiptSelectionPage class="px-8" v-if="isMobileView" />

    <v-card
      v-else
      class="mx-auto my-10 px-4 py-6 d-flex align-center justify-center"
      max-width="400px"
      rounded="lg"
    >
      <ReceiptSelectionPage />
    </v-card>
  </main>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { ref, computed, onMounted } from "vue";
import ReceiptSelectionPage from "~/components/ReceiptSelectionPage.vue";

const { smAndDown } = useDisplay();
const mounted = ref(false);

const isMobileView = computed(() => smAndDown.value);

onMounted(() => {
  mounted.value = true;
});
</script>
